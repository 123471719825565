<template lang="pug">
.email-notifications
  om-heading.mt-1(h1) {{ $t('emailNoti.title') }}
  .d-flex.justify-content-between
    .noti-texts
      span.noti-title {{ $t('emailNoti.settings.alertsAndReports.title') }}
    .action
      template(v-if="isReady")
        om-switch#email-noti-reports.ml-3(
          :value="mailNoti.AlertsAndReports === 'subscribed'"
          :disabled="!isReady"
          @switch="mailStatusChange('AlertsAndReports')"
        )
      template(v-else)
        vue-skeleton-loader(type="rect" :width="36" :height="20" :rounded="true")
  .d-flex.pb-3.noti-divider
    span.noti-desc {{ $t('emailNoti.settings.alertsAndReports.desc') }}

  .d-flex.justify-content-between.pt-3
    .noti-texts
      span.noti-title {{ $t('emailNoti.settings.productUpdates.title') }}
    .action
      template(v-if="isReady")
        om-switch#email-noti-product-updates.ml-3(
          :value="mailchimpNoti.ProductUpdates === 'subscribed'"
          :disabled="!isReady"
          @switch="mailchimpStatusChange('ProductUpdates')"
        )
      template(v-else)
        vue-skeleton-loader(type="rect" :width="36" :height="20" :rounded="true")
  .d-flex.pb-3.noti-divider
    span.noti-desc {{ $t('emailNoti.settings.productUpdates.desc') }}

  .d-flex.justify-content-between.pt-3(v-if="locale === 'en'")
    .noti-texts
      span.noti-title {{ $t('emailNoti.settings.tips.title') }}
    .action
      template(v-if="isReady")
        om-switch#email-noti-tips.ml-3(
          :value="mailchimpNoti.Tips === 'subscribed'"
          :disabled="!isReady"
          @switch="mailchimpStatusChange('Tips')"
        )
      template(v-else)
        vue-skeleton-loader(type="rect" :width="36" :height="20" :rounded="true")
  .d-flex.pb-3
    span.noti-desc {{ $t('emailNoti.settings.tips.desc') }}
</template>

<script>
  import MAILCHIMP_MEMBER_STATUS from '@/graphql/MailchimpMemberStatus.gql';
  import MAIL_MEMBER_STATUS from '@/graphql/MailMemberStatus.gql';
  import CHANGE_MAIL_MEMBER_STATUS from '@/graphql/ChangeMailMemberStatus.gql';
  import CHANGE_MAILCHIMP_MEMBER_STATUS from '@/graphql/ChangeMailchimpMemberStatus.gql';

  import { mapGetters } from 'vuex';

  export default {
    data() {
      return {
        isReady: false,
        mailchimpNoti: {
          Tips: 'not_subscribed_yet',
          ProductUpdates: 'not_subscribed_yet',
        },
        mailNoti: {
          AlertsAndReports: 'not_subscribed_yet',
        },
      };
    },
    computed: {
      ...mapGetters(['getAccountEmail', 'getRegion']),
      locale() {
        return this.getRegion === 'Hungary' ? 'hu' : 'en';
      },
    },

    async mounted() {
      try {
        const [mcMemberStatuses, memberStatuses] = await Promise.all([
          this.getMailChimpMemberStatus(),
          this.getMailMemberStatus(['AlertsAndReports']),
        ]);

        const alertsAndUpdates = memberStatuses.find(
          (member) => member.type === 'AlertsAndReports',
        );
        this.mailNoti.AlertsAndReports = alertsAndUpdates.status;

        const tips = mcMemberStatuses.find(({ type }) => type === 'Tips');
        const productUpdates = mcMemberStatuses.find(({ type }) => type === 'ProductUpdates');

        this.mailchimpNoti.Tips = tips?.status ?? 'unsubscribed';
        this.mailchimpNoti.ProductUpdates = productUpdates?.status ?? 'unsubscribed';
      } catch (e) {
        console.error('Failed to get member statuses', e);
      }

      this.isReady = true;
    },
    methods: {
      async mailchimpStatusChange(type) {
        const memberStatus = await this.changeMailchimpMemberStatus(
          type,
          this.getSwitchedStatus(this.mailchimpNoti[type]),
        );
        this.mailchimpNoti[type] = memberStatus.status;
      },
      async mailStatusChange(type) {
        const memberStatus = await this.changeMailMemberStatus(
          type,
          this.getSwitchedStatus(this.mailNoti[type]),
        );

        this.mailNoti[type] = memberStatus.status;
      },
      getSwitchedStatus(status) {
        return status === 'subscribed' ? 'unsubscribed' : 'subscribed';
      },
      async getMailChimpMemberStatus() {
        const {
          data: { memberStatus },
        } = await this.$apollo.query({
          query: MAILCHIMP_MEMBER_STATUS,
        });

        return memberStatus;
      },
      async changeMailchimpMemberStatus(type, status) {
        const {
          data: { memberStatus },
        } = await this.$apollo.query({
          query: CHANGE_MAILCHIMP_MEMBER_STATUS,
          variables: {
            type,
            status,
          },
        });

        return memberStatus;
      },
      async changeMailMemberStatus(type, status) {
        const {
          data: { memberStatus },
        } = await this.$apollo.query({
          query: CHANGE_MAIL_MEMBER_STATUS,
          variables: {
            type,
            status,
          },
        });

        return memberStatus;
      },
      async getMailMemberStatus(type) {
        const {
          data: { memberStatuses },
        } = await this.$apollo.query({
          query: MAIL_MEMBER_STATUS,
          variables: {
            type,
          },
        });

        return memberStatuses;
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .email-notifications
    max-width: 628px
    font-size: 12px
    padding-left: 25px
    .heading
      margin-bottom: 24px
    .noti-title
      display: block
      color: $om-gray-700
    .noti-desc
      margin-top: 4px
      display: block
      color: $om-gray-600
      max-width: 470px
    .noti-divider
      border-bottom: 1px solid $om-gray-300
</style>
